import React from "react"
import Layout from "../components/layout"
import logoNode from "../img/logo-node.png"
import logoPhp from "../img/logo-php.png"
import logoMySql from "../img/logo-mysql.png"
import logoMongo from "../img/logo-mongo.png"
import logoJava from "../img/logo-java.png"
import logoSwift from "../img/logo-swift.png"
import logojQuery from "../img/logo-jquery.png"
import logoReact from "../img/logo-react.png"
import logoAngular from "../img/logo-angular.png"
import logoHtml5 from "../img/logo-html5.png"
import logoCss3 from "../img/logo-css3.png"
import logoJs from "../img/logo-js.png"
import logoSketch from "../img/logo-sketch.png"
import logoPs from "../img/logo-ps.png"
import logoAi from "../img/logo-ai.png"
import logoInvision from "../img/logo-invision.png"
import logoSublime from "../img/logo-sublime.png"
import logoXcode from "../img/logo-xcode.png"
import logoAndroid from "../img/logo-android.png"
import logoSlack from "../img/logo-slack.png"

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="cover-heading-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <h1 className="cover-heading">Helping startups &amp; companies.</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="cover-wrapper cover-services">
        <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover"></div>
          </div>
        </div>
      </div>
      <section className="p-t-100 p-b-20 sm-p-t-30 sm-p-b-0">
        <div className="container p-t-100 p-b-100 sm-p-t-30 sm-p-b-30">
          <div className="row">
            <div className="col-sm-8 ">
              <h1 className="">
                Let's build something
                <br />
                great together!
              </h1>
              <p className="m-t-50">
                We've been there, done that. Launching our own products has
                given us the experience and insight to help other entrepreneurs,
                startups &amp; companies. We work closely with you to plan,
                prototype, build and finally launch your product to your target
                audience.
              </p>
              <p>
                Just tell us your great idea, answer a few questions for us and
                let us handle the rest while you relax at the beach sipping your
                favorite cocktail.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="">
                <h1>
                  UI/UX
                  <br />
                  Design
                </h1>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                To us design is a serious business. We believe that design is
                not just what it looks like and feels like. Design is how it
                works.<i>[thank you Steve!]</i>. We have embraced simplicity and
                minimalism to produce work that gives user a peace-of-mind.
              </p>
              <p>
                Our design process doesn't start in our <i>Macs!</i>. It starts
                with people and their unique requirements. We conduct research
                to understand what problems the user is currently facing and we
                focus on how to solve them by providing a greater user
                experience. This ensures the end product will not only look
                stunning but also intuitive and easy-to-use, which serves a
                business purpose — to increase sign ups, conversions, traffic
                and sales.
              </p>
            </div>
          </div>
          <div className="row m-t-50 sm-m-t-20">
            <div className="col-md-5">
              <div className="">
                <h1>
                  Web
                  <br />
                  Development
                </h1>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                Your website is the cornerstone of your marketing that helps you
                reach a global audience. We analyze your industry and your
                competitors to deliver compelling digital experiences that
                establishes your brand ahead of the curve.
              </p>
              <p>
                Whether you're looking to develop a simple website like a
                landing page, online portfolio, corporate site or a bespoke web
                application like blog, E-commerce platform, we have experienced
                developers to craft them all for you, always with an eye for
                perfection.
              </p>
              <p>
                All our websites and web apps are responsive by default, which
                means we design them to look great and function easily on mobile
                devices, tablets, and desktop screens. Also we never forget to
                make them highly optimized for faster-loading and better viewing
                experience.
              </p>
            </div>
          </div>
          <div className="row m-t-50 sm-m-t-20">
            <div className="col-md-5">
              <div className="">
                <h1>
                  Mobile
                  <br />
                  Development
                </h1>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                Thanks to mobile, the reach of digital platforms is vast and the
                opportunities to communicate and engage audiences is ever
                expanding. We craft experiences across smartphones and tablets,
                with our expertise in native iOS, Android development and
                cross-platform frameworks such as React Native.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className=" m-t-50">
        <div className="container p-t-50 p-b-50 sm-p-t-10 sm-p-b-10">
          <h4 className="hint-text">Technologies</h4>

          <ul className="technologies-logos m-t-20">
            <li>
              <img alt="NodeJS logo" src={logoNode} width="139" />
            </li>
            <li>
              <img alt="PHP logo" src={logoPhp} width="98" />
            </li>
            <li>
              <img alt="MySQL logo" src={logoMySql} width="127" />
            </li>
            <li>
              <img alt="MongoDB logo" src={logoMongo} width="172" />
            </li>
            <li>
              <img alt="Java logo" src={logoJava} width="110" />
            </li>
            <li>
              <img alt="Swift logo" src={logoSwift} width="123" />
            </li>
          </ul>
          <ul className="technologies-logos">
            <li>
              <img alt="jQuery logo" src={logojQuery} width="127" />
            </li>
            <li>
              <img alt="ReactJS logo" src={logoReact} width="115" />
            </li>
            <li>
              <img alt="Angular logo" src={logoAngular} width="146" />
            </li>
            <li>
              <img alt="HTML5 logo" src={logoHtml5} width="58" />
            </li>
            <li>
              <img alt="CSS3 logo" src={logoCss3} width="60" />
            </li>
            <li>
              <img alt="JS logo" src={logoJs} width="60" />
            </li>
          </ul>
          <br />
          <h4 className="hint-text">Tools</h4>
          <ul className="tools-logos m-t-30">
            <li>
              <img alt="Sketch logo" src={logoSketch} width="42" />
            </li>
            <li>
              <img alt="Photoshop logo" src={logoPs} width="45" />
            </li>
            <li>
              <img alt="Adobe Illustrator logo" src={logoAi} width="45" />
            </li>
            <li>
              <img alt="InVision logo" src={logoInvision} width="45" />
            </li>
            <li>
              <img alt="Sublime logo" src={logoSublime} width="48" />
            </li>
            <li>
              <img alt="XCode logo" src={logoXcode} width="45" />
            </li>
            <li>
              <img alt="Android logo" src={logoAndroid} width="45" />
            </li>
            <li>
              <img alt="Slack logo" src={logoSlack} width="45" />
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}
